import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginBms from "@/views/LoginBms.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginBms
  },
  {
    path: '/navbar',
    name: 'navbar',
    component: () => import("../components/NavBar.vue"),
    props: true,
    children: [
      {
        path: '/CustomerList',
        name: 'CustomerList',
        component: () => import("../components/CustomerList.vue"),
      },
      {
        path: '/AdminsList',
        name: 'AdminsList',
        component: () => import("../components/AdminsList.vue"),
      },
      {
        path: '/EmailSettings',
        name: 'EmailSettings',
        component: () => import("../components/EmailTemplate.vue"),
      },
      {
        path: "/AccountSettings",
        name: 'AccountSettings',
        component: () => import("../components/FeeSettings.vue"),
      },
      {
        path: "/CustomerRequest",
        name: 'CustomerRequest',
        component: () => import("../components/CustomerRequest.vue"),
      },
      {
        path: "/MailSettings",
        name: 'MailSettings',
        component: () => import("../components/MailtiggerSettings.vue"),

      },
      {
        path: "/DateSettings",
        name: 'DateSettings',
        component: () => import("../components/DateSettings.vue"),


      },
      {
        path: "/ApiSendinvoice",
        name: "ApiSendinvoice",
        component: () => import("@/components/ApiSendinvoice.vue")
      },
      {
        path: "/TemplatesType",
        name: "TemplatesType",
        component: () => import("@/components/TemplatesType.vue")
      }

    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

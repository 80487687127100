<template>
  <!-- eslint-disbale -->
<div  id="app">
  <v-app>
  <router-view />
 </v-app>
</div>
</template>

<script>
/* eslint-disable */

export default {
components: {

},
};

</script>
<style lang="css">
@import "@/assets/Styling.css";
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;500;700&display=swap");

#app {
font-family: Sora, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: left;
color: #2c3e50;
}

</style>



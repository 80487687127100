import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import { Amplify } from 'aws-amplify';

Vue.config.productionTip = false
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_tCRQs1PRr",
    region: "ap-south-1",
    userPoolWebClientId: "1jefcfguvqijm469dkb0m62u0f",
  },
  API: {

    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: 'https://qoyin5nr4bbypnqltbhzdbluv4.appsync-api.ap-south-1.amazonaws.com/graphql',
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
})

new Vue({
  router,
  vuetify,

  store,
  render: h => h(App)
}).$mount('#app')

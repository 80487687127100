import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {

                primaryColor: "#7e1636",
                secondaryColor: '#10559A',



            },
        }
    },
});

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    pAuth: false,
    pUserEmail: "",
    sessionid: "",
    currentdetails:{},
  },
  getters: {
    GetAuth: state => {
      return state.pAuth || false;
    },

    GetUserEmail: state => {
      return state.pUserEmail;
    },
    Getsessionid: state => {
      return state.sessionid;
    },
    Getcurrentdetails: state => {
      return state.currentdetails;
    }
  },
  mutations: {
    SetAuth(state, status) {
      state.pAuth = status;
    },
    // To set current user email
    SetUserEmail(state, useremail) {
      state.pUserEmail = useremail;
    },
    Setsessionid(state, sessionid) {
      state.sessionid = sessionid;
    },
    Setcurrentdetails(state,currentdetails){
      state.currentdetails=currentdetails;
    }
    
  },
  modules: {
  }
})

export default store

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const get_current_details = /* GraphQL */ `
  query Get_current_details($input: GetCurrentDetailsInput) {
    get_current_details(input: $input)
  }
`;
export const list_bms_user = /* GraphQL */ `
  query List_bms_user($input: ListBmsUserInput) {
    list_bms_user(input: $input)
  }
`;
export const get_bms_portal_user = /* GraphQL */ `
  query Get_bms_portal_user($input: ListBmsPortalUserInput) {
    get_bms_portal_user(input: $input)
  }
`;
export const get_bms_portal_user_by_id = /* GraphQL */ `
  query Get_bms_portal_user_by_id($input: ListBmsPortalUserByIdInput) {
    get_bms_portal_user_by_id(input: $input)
  }
`;
export const get_bms_master_settings = /* GraphQL */ `
  query Get_bms_master_settings($input: ListBmsMasterSettingsInput) {
    get_bms_master_settings(input: $input)
  }
`;
export const get_bms_org_list = /* GraphQL */ `
  query Get_bms_org_list($input: ListBmsOrgInput) {
    get_bms_org_list(input: $input)
  }
`;
export const get_bms_org_list_by_id = /* GraphQL */ `
  query Get_bms_org_list_by_id($input: ListBmsOrgByIdInput) {
    get_bms_org_list_by_id(input: $input)
  }
`;
export const get_lead_capture_list = /* GraphQL */ `
  query Get_lead_capture_list($input: LeadCaptureListInput) {
    get_lead_capture_list(input: $input)
  }
`;
export const get_bms_mail_trigger_list = /* GraphQL */ `
  query Get_bms_mail_trigger_list {
    get_bms_mail_trigger_list
  }
`;
export const get_sales_partner_details = /* GraphQL */ `
  query Get_sales_partner_details {
    get_sales_partner_details
  }
`;
export const get_bms_mail_placholders = /* GraphQL */ `
  query Get_bms_mail_placholders {
    get_bms_mail_placholders
  }
`;
export const get_bms_master_date_formats = /* GraphQL */ `
  query Get_bms_master_date_formats {
    get_bms_master_date_formats
  }
`;
export const GetOrgGlobalList = /* GraphQL */ `
  query GetOrgGlobalList {
    GetOrgGlobalList
  }
`;
export const GetOrgCustomerGlobalList = /* GraphQL */ `
  query GetOrgCustomerGlobalList($input: GetOrgCustomerGlobalListInput) {
    GetOrgCustomerGlobalList(input: $input)
  }
`;
export const GetOrgCustomerAccGlobalList = /* GraphQL */ `
  query GetOrgCustomerAccGlobalList($input: GetOrgCustomerAccGlobalListInput) {
    GetOrgCustomerAccGlobalList(input: $input)
  }
`;
